import { useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

const WorkflowsFormPage = () => {
  const { linkData } = useParams()

  let baserowUrl = ''
  if (linkData) {
    baserowUrl = linkData
  }

  return (
    <>
      <Metadata title="Workflows Public" description="Workflows Public page" />
      <div className="h-screen">
        <iframe
          id={'baserow-frame'}
          title={'baserow-frame'}
          width={'100%'}
          height={'100%'}
          src={`${process.env.BASEROW_URL}${baserowUrl}`}
        />
      </div>
    </>
  )
}

export default WorkflowsFormPage
